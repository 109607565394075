/*
	TOP NAVIGATION STYLES
	*/

/* ==========================================================================
   Imports/Includes
   ========================================================================== */
@import '../variables';
@import '../mixins';


.modal-mask {
	position:fixed;
	width:100%;
	height:100%;
	display:none;
	top:0;
	left:0;
	z-index:1000;
	@include alpha-attribute('background-color', rgba($BLACK, 0.7), $WHITE);
}
.modal-container {
	position:fixed;
	top:10%;
	left:50%;
	height:auto;
	background-color: transparent;
	-webkit-border-radius: 50px 50px 50px 50px;
	-moz-border-radius: 50px 50px 50px 50px;
	border-radius: 50px 50px 50px 50px;
	padding:10px 10px 10px 10px;
	@include breakWidth(800) {
		top:0;
		left:0;
		width: 100%;
		height: 100%;
	}
	@include breakHeight(600) {
		top:0;
		left:0;
		width: 100%;
		height: 100%;
	}
	.inner-border {
		position:relative;
		border:1px solid $WHITE;
		-webkit-border-radius: 40px 40px 40px 40px;
		-moz-border-radius: 40px 40px 40px 40px;
		border-radius: 40px 40px 40px 40px;
		padding:30px 30px 30px 30px;
	}
	.close-btn-x {
		position:absolute;
		top:10px;
		right:10px;
		width:60px;
		height:60px;
		background-image:url(../images/svg/close-modal-btn-x.php?col1=FFFFFF);
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		cursor:pointer;
		@include breakWidth(1280) {
			width:40px;
			height:40px;
		}
		@include breakHeight(600) {
			width:40px;
			height:40px;
		}
		&:hover {
			$col1: str-replace('#{$THEMECOLOR}','#','');
			background-image:url(../images/svg/close-modal-btn-x.php?col1=#{$col1});
			transition: 0.5s background-image;
		}

	}
}

/*
	video modal
	*/
.modal-container {
	video {
		position:relative;
		vertical-align: middle;
		/*width: 90%;
		left:5%;
		height: 90%;*/

		
	}
}