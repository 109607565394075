@import 'normalize';

/* ==========================================================================
   Imports/Includes
   ========================================================================== */
@import 'variables';
@import 'mixins';



p,img,table,td,tr,div,section,article,header,video,input,a { background-repeat:no-repeat; }
p,div,section,header,input[type=text],textarea,select,article { box-sizing: border-box; }
/*
	MAIN CONTAINER
	*/
.main_container {
	position:relative;
	width: 1680px;
	left:50%;
	margin-left:-840px;
	@include breakWidth(1600) {
		width:1400px;
		left:50%;
		margin-left:-700px;
	}
	@include breakWidth(1433) {
		width:1200px;
		left:50%;
		margin-left:-600px;
	}
	@include breakWidth(1280) {
		width:1200px;
		left:50%;
		margin-left:-600px;
	}
	@include breakWidth(1024) {
		width:1000px;
		left:50%;
		margin-left:-500px;
	}
	@include breakWidth(800) {
		width:98%;
		left:1%;
		margin-left:0px;
	}
	@include breakWidth(740) {
		
	}
	@include breakWidth(460) {
		
	}
	@include breakPrint() {
		width:100%;
		left:0;
	}
}


/* == OTHER WEBSITE STYLE SHEETS */
@import './public-frame';
@import './helpers';


