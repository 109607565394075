/* ==========================================================================
   Imports/Includes
   ========================================================================== */
@import '../variables';
@import '../mixins';



/*
	SECTION - HOME INTRO

	*/
.scroller-anim {
	position:absolute;
	left:50%;
	bottom:10px;
	margin-left:-13px;
	width:25px;
	@include breakWidth(800) {
		display:none;
	}
}

.section-bg-mobi {
	display:none;
	width:100%;
	@include breakWidth(800) {
		display:block;
	}
}
.animal-section {
	.copy-cont {
		position:absolute;
		/*left: 316px;
		top: 512.226px;*/
		left: pxtopercent(315, $fullPageWidth);
	  	top: pxtopercent(444, $sectionHeight);
		width: 40rem; /* pxtopercent(409, $fullPageWidth); */
		height: auto;
		opacity:0;
		margin-top:100px;
		&.is-active {
			opacity:1;
			transition: 1s all;
			margin-top:0px;
		}
		h2 {
			font-size: 3.8rem;
			line-height: 2.5rem;
			/*color: $WHITE;*/
			text-align:left;
			margin-bottom:1rem;
			span {
				&.dots {
					display:inline-block;
					margin-top:-1rem;
					color: $CBROWN;
					font-size:2rem;
				}
			}
		}
		p {
		  font-size: 1.4rem;
		  font-family: $_STDFont;
		  color: $CBROWN;
		  line-height: 1.2;
		  text-align: left;
		}
		.no-mobi {
			@include breakWidth(800) {
				display:none;
			}
		}
	}
}
.section-home-intro {
	padding:0 0 0 0;

	.copy-cont {
		position:absolute;
		/*left: 316px;
		top: 512.226px;*/
		left: pxtopercent(315, $fullPageWidth);
	  	top: pxtopercent(444, $sectionHeight);
		width: 40rem; /* pxtopercent(409, $fullPageWidth); */
		height: auto;
		p {
		  font-size: 1.4rem;
		  font-family: $_STDFont;
		  color: $CBROWN;
		  line-height: 1.2;
		  text-align: left;
		}
	}

	.intro-logo {
		position: absolute;
		/*left: 362.828px;
		top: 364.65px;*/
		left: pxtopercent(159, $fullPageWidth);
		top: pxtopercent(88, $sectionHeight);
		width: pxtopercent(548, $fullPageWidth);
		height: auto;
		@include breakWidth(800) {
			top:70px;
			left:24%;
			width:45%;
			height:auto;
		}
	}
	@include breakWidth(800) {
		padding-top:0 !important;
		.copy-cont {
			padding-bottom:5rem !important;
		}
	}
}


/*
	SECTION H2 - heading styles
	*/
.page-section {
	h2 {
		&.section-h2 {
			font-family: $_STDFont;
			font-size: 4.3rem;
			text-align:center;
			color: $CYELLOW;
			margin-bottom:2rem;
			line-height: 2.5rem;
			span {
				&.dots {
					display:inline-block;
					margin-top:-1rem;
					color: $CBROWN;
					font-size:2rem;
				}
			}
		}
	}
	@include breakWidth(800) {
		padding:5rem 0 0 0;
	}
}

/*
	SECTION - services
	GRID
	*/
.section-services {
	background-color: $BGWHITE;
	.image-hover-cont {
		width: 1474px;
		position:relative;
		left:50%;
		margin-left: -737px;
		.col4 {
			float:left;
			width:25%;
			height:626px;
			background-size:cover;
			background-position:center center;
			background-repeat:no-repeat;
			.mobi-image {
				display:none;
			}
			.hover-cont {
				opacity:0;
				position:relative;
				display:table;
				width:100%;
				height:100%;
				box-sizing: border-box;
				padding:4rem;
				@include alpha-attribute('background-color', rgba($CYELLOW, 0.9),$WHITE);
				&:hover, &.selected {
					opacity:1;
					transition: 0.5s opacity;
				}
				.cell {
					display:table-cell;
					vertical-align: middle;
				}
			}
			.content-cont {
				h3 {
					color: $WHITE;
					font-size:3rem;
					text-align:left;
					font-weight:400;
				}
				p {
					font-size:1.6rem;
					text-align:left;
					font-weight:normal;
					color: $WHITE;
					span { color: $CBROWN; }
				}
			}
			&.w1oo4 {
				width: 25%;
			}
			&.w2oo4 {
				width: 50%;
			}
			&.h1oo2 {
				height: 313px;
			}
			&.h2oo2 {
				height: 626px;
			}
			&.sable {
				background-image:url(../images/services_sable.jpg);
			}
			&.nyala {
				background-image:url(../images/services_nyala.jpg);
				.hover-cont {
					@include alpha-attribute('background-color', rgba($CBROWN, 0.9),$WHITE);
				}
				.content-cont {
					h3 {
						color: $WHITE;
					}
					p {
						color: $WHITE;
						span { color: $CYELLOW; }
					}
				}
			}
			&.civet {
				background-image:url(../images/service_civet.jpg);
				.content-cont {
					p {
						span { color: $CBROWN; }
					}
				}
			}
			&.warthog-skull {
				background-image:url(../images/services_warthog.jpg);
				.hover-cont {
					@include alpha-attribute('background-color', rgba($WHITE, 0.9),$WHITE);
				}
				.content-cont {
					h3 {
						color: $CBROWN;
					}
					p {
						color: $CBROWN;
						span { color: $CYELLOW; }
					}
				}
			}
			&.bushbuck {
				background-image:url(../images/services_bushbuck.jpg);
				.hover-cont {
					@include alpha-attribute('background-color', rgba($CBROWN, 0.9),$WHITE);
				}
				.content-cont {
					h3 {
						color: $WHITE;
					}
					p {
						color: $WHITE;
						span { color: $CYELLOW; }
					}
				}
			}
		}
		@include breakWidth(1600) {
			width:1280px;
			margin-left:-640px;
		}
		@include breakWidth(1280) {
			width:800px;
			margin-left:-400px;
			.col4 {
				float:left;
				width:50%;
				height:313px;
				background-size:cover;
				background-position:center center;
				background-repeat:no-repeat;
				&.w1oo4 {
					width: 50%;
				}
				&.w2oo4 {
					width: 100%;
				}
				&.h1oo2 {
					height: 313px;
				}
				&.h2oo2 {
					height: 600px;
				}
			}
		}
		@include breakWidth(800) {
			width:640px;
			margin-left:-320px;
			.col4 {
				float:left;
				width:50%;
				height:313px;
				background-size:cover;
				background-position:center center;
				background-repeat:no-repeat;
				
				&.w1oo4 {
					width: 50%;
				}
				&.w2oo4 {
					width: 100%;
				}
				&.h1oo2 {
					height: 313px;
				}
				&.h2oo2 {
					height: 600px;
				}
			}
		}
		@include breakWidth(480) {
			width:90%;
			margin-left:0;
			left:5%;
			.col4 {
				float:left;
				width:100%;
				height:auto;
				background-image:none;
				margin-bottom:10px;
				clear:both;
				.mobi-image {
					display:block;
					width:100%;
				}
				&.w1oo4 {
					width: 100%;
				}
				&.w2oo4 {
					width: 100%;
				}
				&.h1oo2 {
					height: auto;
				}
				&.h2oo2 {
					height: auto;
				}
				&.sable {
					background-image:none;
				}
				&.nyala {
					background-image:none;
				}
				&.civet {
					background-image:none;
				}
				&.warthog-skull {
					background-image:none;
				}
				&.bushbuck {
					background-image:none;
				}
				.hover-cont {
					opacity:1;
					position:relative;
					display:block;
					width:100%;
					height:auto;
					box-sizing: border-box;
					padding:3.5rem;
					@include alpha-attribute('background-color', rgba($CYELLOW, 1),$WHITE);
					.cell {
						display:block;
					}
				}
				.content-cont {
					h3 {
						font-size:3rem;
					}
					p {
						font-size:1.6rem;
					}
				}
			}
		}
	}

}

/*
	SECTION - PORTFOLIO
	*/
.section-portfolio {
	background-color: $BGGREY1;
	h2 {
		color: $CBROWN !important;
		span {
			&.dots {
				color: $CYELLOW !important;
			}
		}
	}
	.gallery-cont {
		width: 1616px;
		position:relative;
		left:50%;
		margin-left: -818px;
		.item-cont {
			position:relative;
			float:left;
			width:313px;
			height:313px;
			margin-right:10px;
			margin-bottom:10px;
			background-size:cover;
			background-position:center center;
			background-repeat: no-repeat;
			background-color: $CBROWN;
			&:after {
				position:absolute;
				top:0;
				left:0;
				content:"";
				width:100%;
				height:100%;
				@include alpha-attribute('background-color', rgba($BLACK,0), $WHITE);
			}
			&:hover:after {
				transition: 0.25s all;
				@include alpha-attribute('background-color', rgba($BLACK,0.2), $WHITE);
			}
			
			/*
				spyglass icon
				*/
			.spyglass {
				background-image:url(../images/svg/search-glass.php?col1=FFFFFF);
				background-color: transparent;
				background-position:center center;
				background-size: 90%;
				width:25px;
				height:25px;
				position:absolute;
				bottom:4px;
				right:4px;
			}
			&:hover > .spyglass {
				transition: 0.25s all;
				opacity:0;
			}
		}
		@include breakWidth(1600) {
			width:1280px;
			margin-left:-636px;
			.item-cont {
				width:246px;
				height:246px;
			}
		}
		@include breakWidth(1280) {
			width:800px;
			margin-left:-400px;
			.item-cont {
				width:150px;
				height:150px;
			}
		}
		@include breakWidth(800) {
			width:610px;
			margin-left:-305px;
			.item-cont {
				width:300px;
				height:300px;
				&:nth-child(even) {
					margin-right:0;
				}
				&:nth-child(odd) {
					
				}
				.spyglass {
					width:20px;
					height:20px;
				}
			}
		}
		@include breakWidth(740) {
			width:300px;
			margin-left:-150px;
			.item-cont {
				width:145px;
				height:145px;
				&:nth-child(even) {
					margin-right:0;
				}
				&:nth-child(odd) {
					
				}
			}
		}
	}

	@include breakWidth(800) {
		padding-bottom:5rem;
	}
}


.section-buffalo {
	padding:0 0 0 0;
	.copy-cont {
		position:absolute;
		/*left: 316px;
		top: 512.226px;*/
		left: pxtopercent(1234, $fullPageWidth);
	  	top: pxtopercent(463, $sectionHeight);
		width: 45rem; /* pxtopercent(409, $fullPageWidth); */
		height: auto;
	}
	
}


.section-rooi-hartebees {
	padding:0 0 0 0;
	.copy-cont {
		position:absolute;
		/*left: 316px;
		top: 512.226px;*/
		left: pxtopercent(368, $fullPageWidth);
	  	top: pxtopercent(476, $sectionHeight);
		width: 45rem; /* pxtopercent(409, $fullPageWidth); */
		height: auto;
	}
}
.section-eland {
	padding:0 0 0 0;
	.copy-cont {
		position:absolute;
		/*left: 316px;
		top: 512.226px;*/
		left: pxtopercent(955, $fullPageWidth);
	  	top: pxtopercent(534, $sectionHeight);
		width: 50rem; /* pxtopercent(409, $fullPageWidth); */
		height: auto;
	}
}
.section-blesbok {
	padding:0 0 0 0;
	.copy-cont {
		position:absolute;
		/*left: 316px;
		top: 512.226px;*/
		left: pxtopercent(335, $fullPageWidth);
	  	top: pxtopercent(471, $sectionHeight);
		/* width: 40rem; /* pxtopercent(409, $fullPageWidth); */
		width: pxtopercent(396, $fullPageWidth);
		height: auto;
	}
}


.section-nyala {
	padding:0 0 0 0;
	.copy-cont {
		position:absolute;
		/*left: 316px;
		top: 512.226px;*/
		left: pxtopercent(1099, $fullPageWidth);
	  	top: pxtopercent(510, $sectionHeight);
		/* width: 58rem; /* pxtopercent(409, $fullPageWidth); */
		width: pxtopercent(682, $fullPageWidth);
		height: auto;
		p {
			color: $CYELLOW;
		}
	}
}
.section-vlakvark {
	padding:0 0 0 0;
	.copy-cont {
		position:absolute;
		/*left: 316px;
		top: 512.226px;*/
		left: pxtopercent(358, $fullPageWidth);
	  	top: pxtopercent(465, $sectionHeight);
		/* width: 37rem; /* pxtopercent(409, $fullPageWidth); */
		width: pxtopercent(393, $fullPageWidth);
		height: auto;
	}
}
.section-koedoe {
	padding:0 0 0 0;
	.copy-cont {
		position:absolute;
		/*left: 316px;
		top: 512.226px;*/
		left: pxtopercent(1059, $fullPageWidth);
	  	top: pxtopercent(489, $sectionHeight);
		/* width: 58rem; /* pxtopercent(409, $fullPageWidth); */
		width: pxtopercent(574, $fullPageWidth);
		height: auto;
		p {
			color: $CYELLOW;
		}
	}
}
.section-rooibok {
	padding:0 0 0 0;
	.copy-cont {
		position:absolute;
		/*left: 316px;
		top: 512.226px;*/
		left: pxtopercent(461, $fullPageWidth);
	  	top: pxtopercent(495, $sectionHeight);
		/* width: 60rem; /* pxtopercent(409, $fullPageWidth); */
	  	width: pxtopercent(602, $fullPageWidth);
		height: auto;
	}
}
.section-bosbok {
	padding:0 0 0 0;
	.copy-cont {
		position:absolute;
		/*left: 316px;
		top: 512.226px;*/
		left: pxtopercent(899, $fullPageWidth);
	  	top: pxtopercent(472, $sectionHeight);
		/* width: 62rem; /* pxtopercent(409, $fullPageWidth); */
	  	width: pxtopercent(613, $fullPageWidth);
		height: auto;
		p {
			color: $CYELLOW;
		}
	}
}
.section-blou-wildebees {
	padding:0 0 0 0;
	.copy-cont {
		position:absolute;
		/*left: 316px;
		top: 512.226px;*/
		left: pxtopercent(451, $fullPageWidth);
	  	top: pxtopercent(540, $sectionHeight);
		/* width: 63rem; /* pxtopercent(409, $fullPageWidth); */
	  	width: pxtopercent(640, $fullPageWidth);
		height: auto;
	}
}
.section-kringgat {
	padding:0 0 0 0; 
	.copy-cont {
		position:absolute;
		/*left: 316px;
		top: 512.226px;*/
		left: pxtopercent(965, $fullPageWidth);
	  	top: pxtopercent(554, $sectionHeight);
		/* width: 64rem; /* pxtopercent(409, $fullPageWidth); */
		width: pxtopercent(630, $fullPageWidth);
		height: auto;
		p {
			color: $CYELLOW;
		}
	}
}
.section-swart-wildebees {
	padding:0 0 0 0;
	.copy-cont {
		position:absolute;
		/*left: 316px;
		top: 512.226px;*/
		left: pxtopercent(314, $fullPageWidth);
	  	top: pxtopercent(554, $sectionHeight);
		/* width: 47rem; /* pxtopercent(409, $fullPageWidth); */
		width: pxtopercent(500, $fullPageWidth);
		height: auto;
	}
}



/*
	GO MOBILE FOR ANIMATL SECTIONS
	*/
.animal-section {
	.copy-cont {
		@include breakWidth(800) {
			/*display:none;*/
			opacity:1;
			position:relative;
			left:0;
			top:0;
			width:100%;
			margin-top:0;
			padding:20px;
			background-color: #d5cfc5;
			h2 {
				display:none;
			}
			p {
				font-size: 1.5rem;
				font-family: $_STDFont;
				color: $CBROWN;
				line-height: 1.2;
				text-align: left;
				&.yellow-text, .yellow-text, &.white-text, .white-text {
					color: $CBROWN !important;
			  	}
			}
		}
	}
	&.section-swart-wildebees {
		.copy-cont {
			@include breakWidth(740) {
				padding-bottom:5rem;
			}
		}
	}
}



/*
	HOME BOTTOM ABOUT
	*/
.section-home-about {
	padding:5rem 0 0 0;
	background-color: #f4f1ea;
	.copy-cont {
		h2 {
			text-align:center;
		}
		position:relative;
		left:50%;
		width:680px;
		margin-left:-340px;
		padding-bottom:50px;
		@include breakWidth(740) {
			width:100%;
			left:0%;
			margin-left:0;
			padding:0 20px 20px 20px;
			h2 {
				line-height:1;
			}
		}
	}
	/*@include breakWidth(800) {
		padding:0 0 0 0;
	}*/

}


/*
	SECTION - CONTACT US
	*/
.section-contact-us {
	padding:0 0 0 0;
	background-color: #d5cfc5;
	.left-container, .right-container {
		float:left;
		width:50%;
		box-sizing: border-box;
		padding:5rem 12rem;
		h2 {
			margin-bottom:10rem;
		}
		@include breakWidth(800) {
			width:100%;
			padding:5rem 20px;
			h2 {
				line-height:2.5rem;
				margin-bottom:5rem;
			}
		}
	}
	.left-container {
		background-color: #d5cfc5;
	}
	.right-container {
		background-color: #e7e2d8;
		position:relative;
	}
	h2, p {
		text-align:left !important;
	}
	p {
		font-size:2rem;
		color: $CBROWN;
		line-height:1.5;
	}
	form {
		label {
			float:left;
			width:20%;
			height:3rem;
			line-height:3rem;
			margin-bottom:1.2rem;
			font-size:2rem;
			box-sizing: border-box;
			@include breakWidth(460) {
				width:100%;
				margin-bottom:0.5rem;
			}
		}
		input, textarea {
			float:left;
			width:80%;
			height:3rem;
			line-height:3rem;
			margin-bottom:1.2rem;
			background-color: $WHITE;
			border: 1px solid #d5cfc5;
			padding:5px 10px;
			box-sizing: border-box;
			font-size:1.6rem;
			@include breakWidth(460) {
				width:100%;
				margin-bottom:2rem;
			}
			&.frame_input_marked {
				border: 1px solid $RED;
			}
			&.frame_input_checked {
				border: 1px solid $CGREEN;
			}
		}
		textarea {
			resize: none;
			height: 250px;
			
		}
		button {
			float:right;
			background-color: $CBROWN;
			font-size:20px;
			color: $WHITE;
			text-align:center;
			padding:5px;
			&:hover {
				background-color: $CYELLOW;
			}
			@include breakWidth(460) {
				width:100%;
			}
		}
	}
	.server-message-cont {
		padding:0 3rem 0 0;
		display:none;
		h3 {
			font-size:2rem;
			text-align:left;
			color: $CBROWN;
		}
	}
	/*
	*
	*/
	.loading-screen {
	    position:absolute;
	    top:0;
	    left:0;
	    width:100%;
	    height:100%;
	    display:none;
	    background-color:rgba(255,255,255,1);
	    @include alpha-attribute('background-color', rgba($BGGREY1,0.9), $WHITE);
	    svg path, svg rect{
	        fill: $CBROWN;
	    }
	    .loader--style8 {
	        position:absolute;
	        left:50%;
	        top:50%;
	        width:48px;
	        height:60px;
	        margin-left:-24px;
	        margin-top:-30px;
	    }

	}
}


@import './plugins/owl.carousel.min.css';