/*
	TOP NAVIGATION STYLES
	*/

/* ==========================================================================
   Imports/Includes
   ========================================================================== */
@import '../variables';
@import '../mixins';


/*
	FOOTER
	main page footer
	*/
footer {
	width:100%;
	height:auto;
	position:relative;
	background-color: $BLACK;
	padding-bottom:0;
	.orange-strip {
		position:relative;
		width:100%;
		height:6.5rem;
		background-color: $CYELLOW;
	}
	.grey-strip {
		position:relative;
		width:100%;
		height:1.5rem;
		background-color: #d5cfc5;
	}
}