@import 'normalize';

/* ==========================================================================
   Imports/Includes
   ========================================================================== */
@import 'variables';
@import 'mixins';
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i|Roboto:100,100i,300,300i,400,700,700i,900|Roboto+Mono|Roboto+Slab');
/*@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i');*/
@import url('https://fonts.googleapis.com/css?family=Arimo:400,400i,700,700i');


html {
    min-height: 100% !important;
}
html {
    /* FONT SIZES */
    font-size:10px;
    @include breakWidth(1600) {
		font-size:9px;
	}
	@include breakWidth(1433) {
		font-size:9px;
	}
	@include breakWidth(1280) {
		font-size:8px;
	}
	@include breakWidth(1024) {
		font-size:7px;
	}
	@include breakWidth(800) {
		font-size:7px;
	}
	@include breakWidth(740) {
		font-size:7px;
	}
	@include breakWidth(460) {
		font-size:8px;
	}
	@include breakPrint() {
		font-size:8px;
	}
}

body {
    height:auto;
    min-height:0px !important;
    font-family: $_STDFont;
    margin-top:60px;
    background-color: #c9c1b4;
    &.nav-is-open{
	    height:100%;
	    overflow:hidden;
	}
    @include breakWidth(800) {
    	margin-top:0;
	}
    @include breakWidth(700) {
    }
}


/* GENERAL */
h1, h2, h3, h4, h5 {
	font-family: $_STDFont;
	font-weight:normal;
	margin:0px 0px 0px 0px;
	padding:0px 0px 0px 0px;
	text-transform:none;
	line-height:1em;
}

a {
	color: $THEMECOLOR;
	&:hover {
		@include alpha-attribute('color', rgba($THEMECOLOR, 0.5), $WHITE);
		text-decoration:none;
	}
}

a:focus, a:active {
   background-color:inherit;
   color:inherit;
}

p, li, td, ul, label, input, button { font-family: $_STDFont; font-size: 1.6rem; margin:0px 0px 0px 0px; padding:0px 0px 0px 0px; }
button { border:0px solid $WHITE; }

p,img,table,td,tr,div,section,article,header,video,input,a { background-repeat:no-repeat; }
p,div,section,header,input,textarea,select,article { box-sizing: border-box; }

p strong, li strong, h1 strong, h2 strong, h3 strong, td strong, button strong {
	font-weight:700;
}
p, li {
	font-family: $_STDFont;
	font-weight:300;
}

p, li, h1, h2, h3, button, td, span {
	&.ctheme {
		color: $THEMECOLOR !important;
	}
	&.cyellow {
		color: $CYELLOW !important;
	}
	&.corange {
		color: $CORANGE !important;
	}
	&.yellow-text {
		color: $CYELLOW !important;
	}
	&.white-text {
		color: $WHITE !important;
	}
	&.brown-text {
		color: $CBROWN !important;
	}
} 





/*
	PAGE SECTION
	*/
.page-section {
	position:relative;
	width:100%;
	height:auto;
	margin:0 0 0 0;
	box-sizing: border-box;
	clear:both;

	background-position:left center;
	background-size:cover;
	background-repeat:no-repeat;
	padding:10rem 0 13rem 0;

	&:after {
	  content: "";
	  display: table;
	  clear: both;
	}

	.section-bg {
		position:relative;
		display:block;
		width:100%;
		height:auto;
		@include breakWidth(800) {
			display:none;
		}
	}

	h1, h2 {
		font-size:5.4rem;
		text-align:left;
		font-weight:400;
		margin-bottom:3rem;
	}
	h2 {
		text-align: center;
	}

}

/*
	href-styled button
	*/
a, button {
	&.href-button {
		display:inline-block;
		position:relative;
		
		line-height:5rem;
		height:5rem;
		text-decoration:none;
		padding:0 0 0 0;
		font-weight:700;
		background-color: transparent;
		color: $CBLACK;
		font-style: normal;
		text-align:center;
		padding:0 0 0 0;
		text-transform:uppercase;
		box-sizing: border-box;

		&.cblack {
			background-color: $BLACK;
		}
		&.cwhite {
			background-color: $WHITE;
		}

		&:hover {
			background-color: $THEMECOLOR;
			transition: background-color 0.25s;
		}
		@include breakWidth(1600) {
		}
	}
}


/*
	import all the different sections of the pages
	*/
@import './pages/home';
/* -- */










/*
	import other components
	*/
@import './components/topnav';
@import './components/footer';
@import './components/modals';
