$BLACK: #000000;
$COPYBLACK: #444444;
$COPYWHITE: #dcdcdc;
$COPYGREY: #a4a6a8;
$LABELGREY: #c4c4c4;
$MAINGREY: #191f24;
$WHITE: #FFFFFF;
$RED: #d40000;

$THEMECOLOR: #f57e20;

/* HOME PAGE */
$BGGREY1: #eceae6;

/* other */
$BUTTONGREY: #717171;
$BUTTONGREEN: #619900;
$MODALHEADER_BG: #585858;
$MODALHEADER_COLOR: #ffffff;

/* project colors */
$CNAVY: #1b2c34;
$CGREEN: #6dac7c;
$CORANGE: #f57e20;
$CAQUA: #60969D;
$CGOLD: #E8B909;
$CYELLOW: #d19712;
$CDGREY: #292929;
$CDENNIM: #364b55;
$CBLACK: #191919;
$CBROWN: #412e19;
$BGWHITE: #fbfaf8;

$TEXTPADDING: 15px;
/* Fonts
	<link href="https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed|Roboto+Mono|Roboto+Slab" rel="stylesheet">
	@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed|Roboto+Mono|Roboto+Slab');

	font-family: 'Roboto', sans-serif;
	font-family: 'Roboto Condensed', sans-serif;
	font-family: 'Roboto Slab', serif;
	font-family: 'Roboto Mono', monospace;
	*/
$_STDFont: 'Arimo', Helvetica, Arial, sans-serif;
$_STDFontBold: 'Arimo', Helvetica, Arial, sans-serif;
$_STDFontCondensed: 'Times New Roman', TimesNewRoman, Times, 'Lora', serif;
$_STDFontSlab: 'Arimo', Helvetica, Arial, sans-serif;
$_BTNFont: 'Arimo', Helvetica, Arial, sans-serif;
$_ROBOTO: 'Roboto', Arial, Helvetica, sans-serif;
$_ROBOTOCondensed: 'Roboto Condensed', Arial, Helvetica, sans-serif;

/*$_FANCYFONT: "Times New Roman", Times, serif;*/
$_FANCYFONT: 'Lora', serif;

/* Ethnikids variables */
$PageContentWidth: 980px;

