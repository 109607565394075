/*
	TOP NAVIGATION STYLES
	*/

/* ==========================================================================
   Imports/Includes
   ========================================================================== */
@import '../variables';
@import '../mixins';


.topnav-cont {
	position:fixed;
	top:14px;
	left:0;
	width:100%;
	height:46px;
	box-sizing: border-box;
	background-color: $CBROWN;
	transition: 0.25s all;
	z-index:1000;

	&.shrink {
	}
	.topnav {
		border:0px solid $RED;
		ul {
			text-align:center;
			position:relative;
			margin:0 0 0 0;
			padding:0 0 0 0;
			li {
				display:inline-block;
				text-align:center;
				position:relative;
				color: $WHITE;
				font-size:1.8rem;
				
				padding:0 0 0 0;
				a {
					line-height:46px;
					text-decoration: none;
					color: $WHITE;
					transition: 0.25s all;
					letter-spacing:2px;
					.bottom-arrow-cont {
						position:absolute;
						bottom:0;
						left:0;
						opacity:0;
						width:100%;
						height:5px;
						background-color: $CYELLOW;
						img {
							position:absolute;
							bottom:-13px;
							left:50%;
							margin-left:-16px;
						}
					}
					&:hover {
						color: $CYELLOW;
					}
					&:hover > .bottom-arrow-cont {
						opacity: 1;
						transition: 0.5s all;
					}
					&.selected {
						color: $CYELLOW;
						.bottom-arrow-cont {
							opacity: 1;
							transition: 0.5s all;
						}
					}
				}
				img {
					position:absolute;
					bottom:-30px;
					right:40%;
					width:40px;
					height:auto;
				}
				.divider-dot-left {
					display:inline-block;
					color: $CYELLOW;
					padding-right:30px;
				}
				.divider-dot-right {
					display:inline-block;
					color: $CYELLOW;
					padding-left:30px;
				}
			}
			
		}
	}

	.burger-button {

		display:none;
		position:absolute;
		top:0.5rem;
		right:0.5rem;
		line-height:4rem;
		color: $WHITE;
		padding-left: 7rem;
		font-size:1rem;
		padding-right:1rem;
		$col1: str-replace('#{$CYELLOW}', '#');
		$col2: str-replace('#{$WHITE}', '#');
		$col3: str-replace('#{$CBROWN}', '#');
		background-image:url(../images/svg/iconmobile-burger-btn.php?col1=#{$col1}&col2=#{$col2}&col3=#{$col3});
		background-color: transparent;
		background-position:right center;
		background-repeat: no-repeat;
		background-size:4rem 4rem;
		text-transform: uppercase;
		transition:background-image 0.5s;
		&.is-open {
			background-color: $CBROWN;
			background-image:url(../images/svg/iconmobile-burger-x.php?col1=FFFFFF);
			color: $CYELLOW;
			transition:background-image 0.5s;
		}
	}

	/*
		GO MOBILE
		*/
	@include breakWidth(800) {
		position: fixed;
		height:5rem;
		top:0;
		background-color: transparent;
		.main_container {
			height:5rem;
		}
		.topnav {
			display:none;
			position:fixed;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background-color: $CBROWN;
			ul {
				margin-top:5rem;
				width:100%;
				text-align:center;
				position:relative;
				li {
					display:block;
					width:100%;
					font-size:1.8rem;
					padding:0 0 0 0;
					a {
						line-height:6rem;
						font-size:17px;
					}
				}
			}
		}

		.burger-button {
			display:block;
		}
	}
}